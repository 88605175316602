<template>
  <v-dialog
    :value="dialogs.mainDialog"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Добавить админа
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            v-if="forMainDialog.dialog === 'add'"
            :cols="12"
          >
            <v-text-field
              v-model="forMainDialog.admin.username"
              label="Почта"
              dense
              outlined
              hide-details
              :spellcheck="false"
            />
          </v-col>
          <v-col :cols="12">
            <v-text-field
              v-model="forMainDialog.admin.displayName"
              label="Имя"
              dense
              outlined
              hide-details
              :spellcheck="false"
            />
          </v-col>

          <v-col :cols="12">
            <v-text-field
              v-model="forMainDialog.admin.roleName"
              label="Роль"
              dense
              outlined
              hide-details
              :spellcheck="false"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageAdmins"
              label="Управлять админами"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageUsers"
              label="Управлять пользователями"
              hide-details
              class="mt-0"
            />
          </v-col><v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.deleteTeam"
              label="Удалять команды и учётные записи"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="6">
            <v-checkbox
              v-model="forMainDialog.admin.viewPromocodes"
              label="Видеть промокоды"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="6">
            <v-checkbox
              v-model="forMainDialog.admin.managePromocodes"
              label="Управлять промокодами"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="6">
            <v-checkbox
              v-model="forMainDialog.admin.deletePromocodes"
              label="Удалять промокоды"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageReleases"
              label="Управлять релизами"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="6">
            <v-checkbox
              v-model="forMainDialog.admin.manageBrowserBuilds"
              label="Управлять билдами браузера"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="6">
            <v-checkbox
              v-model="forMainDialog.admin.deleteBrowserBuilds"
              label="Удалять билды браузера"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageLogs"
              label="Логи"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageBrowserProfiles"
              label="Браузерные профили"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.viewReferalBalance"
              label="Видеть реферальный баланс"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.resetReferalBalance"
              label="Обнулять реферальный баланс"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.changeReferalPercent"
              label="Менять процент рефских отчислений у юзера"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.managePromoAccess"
              label="Выдавать промо доступы"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.setBranch"
              label="Менять ветки (себе и другим)"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.addSubscriptionToEverybody"
              label="Добавлять подписку всем юзерам в антике одновременно"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.recurrentPayments"
              label="Управлять рекуррентными платежами (изменение курса подписки, отмена подписки...)"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageRefbringers"
              label="Управлять рефоводами"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.managePayments"
              label="Управлять платежами"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageCoinbase"
              label="Управлять coinbase"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageFinanceLogs"
              label="Финансовые логи"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageValues"
              label="Управлять стоимостями"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageMarketingCases"
              label="Управлять маркетинговыми условиями"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col :cols="12">
            <v-checkbox
              v-model="forMainDialog.admin.manageCaptcha"
              label="Управлять капчей"
              hide-details
              class="mt-0"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          :disabled="disablePosting"
          @click="forMainDialog.dialog === 'add' ? addAdmin() : editAdmin()"
        >
          {{ forMainDialog.dialog === 'add' ? 'Добавить' : 'Сохранить' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'AdminsMainDialog',

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters({ dialogs: 'admins/dialogs' }),
    ...mapFields('admins', ['forMainDialog']),

    disablePosting() {
      const { admin } = this.forMainDialog;
      return this.$_.isEmpty(admin.username) || this.$_.isEmpty(admin.displayName) || this.$_.isEmpty(admin.roleName);
    },
  },

  beforeDestroy() {
    this.$store.dispatch('admins/clearForDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('admins/closeDialog', 'mainDialog');
    },

    async addAdmin() {
      try {
        this.loading = true;

        const response = await this.api.post('/admin/admins', this.forMainDialog.admin);
        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('admins/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } catch (_) {
        // Do nothing
      } finally {
        this.loading = false;
      }
    },

    async editAdmin() {
      try {
        this.loading = true;
        const admin = this.$_.cloneDeep(this.forMainDialog.admin);
        delete admin.id;
        delete admin.username;
        const updatedAdmin = {};

        Object.keys(admin).forEach((key) => {
          const value = admin[key];
          if (typeof value === 'boolean') {
            updatedAdmin[key] = value ? 1 : 0;
          } else {
            updatedAdmin[key] = value;
          }
        });

        const response = await this.api.patch(`/admin/admins/${this.forMainDialog.admin.id}`, updatedAdmin);
        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('admins/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } catch (_) {
        // Do nothing
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
